import React from 'react'
import { CASUAL, GAMES, POOL } from '../../../components/internal-links'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Blogs from '../../../components/Blogs/Blogs'
import { POOL_LEARN_PAGES } from '../../../pool-tags'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../../lib/generate-schema'
import { POOL_LEARN as POOL_DOWNLOAD_LINK } from '../../../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: '8 Ball Pool',
    url: POOL,
  },
]

const LearnPool: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POOL_DOWNLOAD_LINK}
    >
      <SEO
        title="Pool, its types &amp; gameplay: Discover the world of Pool | Mega"
        description="Do the basics of Pool and its playing pattern still baffle your mind? Read 15+ articles to clear all your doubts and master the game."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="pool"
        tags={POOL_LEARN_PAGES}
        prefix={`${POOL}` || '/'}
        title={
          <>
            Learn Pool - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnPool
